import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 2em;
  & p {
    padding: 1em 0;
    text-align: justify;
  }
  & h1 {
    margin: 0 0 0 2em;
    font-size: 1.5em;
  }
` 

export const CalendarWrapper = styled.div`
  margin-top:1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  margin-top: 1em;
  & > :last-child {
    margin-top: 1em; 
  }
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start; 
    & > :last-child {
      margin-left: 1em; 
      margin-top: 0; 
    }
  }
`

export const LogoWrapper = styled.img`
  width: 50px;
`

export const ColivingNameWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 1em;
  & h1{
    margin-left: auto;
    margin-right: auto;
  }
`

export const SocialNetworkLogosWrapper = styled.a`
  & img {
    width: 60px;
  }
  margin: 1em 1em 0 0;
  cursor: pointer;
`

export const SocialNetworkContainer= styled.div`
  display: flex;
`

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
`