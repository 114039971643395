import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleAuthentication } from '../utils/authHelpers.ts';
import { login, signUp } from '../services/auth/index.ts';

export const useAuthForm = (initialFormState, formType) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormState);
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let response;
      if (formType === 'signup') {
        response = await signUp(formData);
      } else if (formType === 'login') {
        response = await login(formData);
      }

      const { token } = response;
      handleAuthentication(token);
      setIsError(false);
      navigate('/profile');
    } catch (error) {
      setErrorMessage(error.message);
      setIsError(true);
    }
  };

  const goTo = (path) => {
    navigate(path);
  };

  return {
    formData,
    errorMessage,
    handleChange,
    handleSubmit,
    isError,
    goTo,
  };
};