import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { StyledButton, StyledError, StyledForm } from '../SignUp/wrappers.ts';
import { useState } from 'react';
import { handleAuthentication } from '../../utils/authHelpers.ts';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import { login, signUp } from '../../services/auth/index.ts';

export const AuthDialog = ({setOpen, open, errorTitle}) => {
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    passwordConfirm: '',
  });

  const switchLoginSignUp = () => setIsLogin(!isLogin)

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = isLogin
      ? { email: formData.email, password: formData.password }
      : formData;

    try {
      const response = isLogin 
        ? await login(data)
        : await signUp(data);
      
      const { token } = response;
      handleAuthentication(token);
      navigate('/profile')
      setOpen(false);
    } catch (error) {
      console.error('Error during authentication', error);
      setError(error.message)
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {errorTitle ? errorTitle : (isLogin ? 'Login' : 'Sign up')}        
        </DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-description" >
          <StyledForm onSubmit={handleSubmit}>
            { isLogin ? <>
              <TextField
              type="email"
              name="email"
              label="Email"
              value={formData.email}
              onChange={handleChange}
              variant='standard'
              sx={{width:'13em'}}
              />
              <TextField
              type="password"
              name="password"
              label="Password"
              value={formData.password}
              onChange={handleChange}
              variant='standard'
              sx={{width:'13em'}}
              />
            </>
            : <>
              <TextField
              type="text"
              name="name"
              label="Name"
              value={formData.name}
              onChange={handleChange}
              variant='standard'
              sx={{width:'13em'}}
              />
              <TextField
              type="email"
              name="email"
              label="Email"
              value={formData.email}
              onChange={handleChange}
              variant='standard'
              sx={{width:'13em'}}
              />
              <TextField
              type="password"
              name="password"
              label="Password"
              value={formData.password}
              onChange={handleChange}
              variant='standard'
              sx={{width:'13em'}}
              />
              <TextField
              type="password"
              name="passwordConfirm"
              label="Confirm password"
              value={formData.passwordConfirm}
              onChange={handleChange}
              variant='standard'
              sx={{width:'13em'}}
              />
            </>
            }
            
          {error && <StyledError>{error}</StyledError>}
          <StyledButton type="submit">Send</StyledButton>
        </StyledForm>
        </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={switchLoginSignUp}>{isLogin ? 'Create a new user' : 'Login'}</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
