// components/Profile/index.js
import React from 'react';
import Button from '@mui/material/Button';
import { StyledForm } from '../../components/SignUp/wrappers.ts';
import pic from '../../assets/users/img/default.jpg';
import { CustomH1 } from './wrappers.tsx';
import { useUserProfile } from '../../hooks/useUserProfile.ts';
import { Avatar, IconButton, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { format, differenceInDays } from 'date-fns';

export const Profile = () => {
  const {
    data,
    errorMessage,
    isError,
    handleChange,
    handleSubmit,
    handleLogout,
    isEditing,
    toggleEditMode,
  } = useUserProfile();

  if (!data) {
    return <p>Loading...</p>;
  }

  const photoUrl = data.photo || pic;

  return (
    <div>
      <CustomH1>Profile</CustomH1>
      <Avatar
        alt={`${data.name}'s picture`}
        src={photoUrl}
        sx={{ width: 300, height: 300 }}
      />
      <div>
        <p>Name: {data.name}</p>
      </div>
      <div>
        <p>Email: {data.email}</p>
      </div>
      <div>
        <p>Description: {data.description}</p>
      </div>
      <div>
        <h2>Reservations:</h2>
        {data.reservations.map((reservation) => {
          const startDate = new Date(reservation.start_date);
          const endDate = new Date(reservation.end_date);
          const duration = differenceInDays(endDate, startDate);
          
          return (
            <div key={reservation._id}>
              <p>Duration: {duration} days</p>
              <p>Start Date: {format(startDate, 'MMM dd, yyyy')}</p>
              <p>End Date: {format(endDate, 'MMM dd, yyyy')}</p>
              <p>Price: ${reservation.price_total}</p>
              <hr />
            </div>
          );
        })}
      </div>
      <IconButton onClick={toggleEditMode}>
        <EditIcon />
      </IconButton>
      {isEditing && (
        <StyledForm onSubmit={handleSubmit}>
          <TextField 
            id="name-text-field" 
            label="Name" 
            variant="standard" 
            name='name'
            onChange={handleChange}
            error={isError}
          />
          <TextField 
            id="description-text-field" 
            label="Description" 
            variant="standard" 
            multiline 
            maxRows={4}
            name='description'
            onChange={handleChange}
            error={isError}
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleChange}
          />
          <Button type="submit">Submit</Button>
        </StyledForm>
      )}
      <Button onClick={handleLogout}>Log out</Button>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
  );
};
