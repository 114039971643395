import imageUrl from '../../images/coliving.jpg'
import nine from '../../images/ninecoliving.jpg'
import sunandco from '../../images/sun&co.jpg'
import chateau from '../../images/chateaucoliving.jpg'
import cloudcitadel from '../../images/cloudcitadel.jpeg'
import sende from '../../images/sende.jpg'
import anceu from '../../images/anceu.png'
import pitaya from '../../images/pitaya.png'
import cactus from '../../images/cactus.png'
import maraya from '../../images/Maraya.jpg'
import taoro from '../../images/taoro.jpg'
import ikigai from '../../images/Ikigai.png'
import tomodomo from '../../images/tomodomo.png'
import burgas from '../../images/burgas.png'
import evolve from '../../images/evolve.png'
import fem from '../../images/fem.png'
import Islow from '../../images/Islow.png'
import tohani from '../../images/tohani.jpg'
import gato from '../../images/gato azul.png'
import belafonte from '../../images/belafonte.png'
import slowcowork from '../../images/slowcowork.png'
import samesameliving from '../../images/samesame.png'
import colisbon from '../../images/colisbon.jpg'
import lokal from '../../images/lokal.jpg'
import frilingue from '../../images/frilingue.png'
import co404 from '../../images/co404.png'
import colivingnewlevel from '../../images/colivingnewlevel.jpg'
import dwellazores from '../../images/dwella.jpg'
import vivarium from '../../images/vivarium.png'
import noma from '../../images/noma.jpg'
import navitas from '../../images/navitas.jpg'
import colivingcadiz from '../../images/colivingcadiz.png'
import nomadcoliving from '../../images/nomadcoliving.jpg'
import nomadgao from '../../images/nomadgao.png'
import ourescape from '../../images/ourescape.png'
import hivecoliving from '../../images/hivecoliv.png'
import franzundmathilde from '../../images/franz&mathilde.png'
import hub53 from '../../images/hub53.png'
import treballu from '../../images/treballu.png'
import debed from '../../images/debed.png'
import heyday from '../../images/heyday.png'
import cummary from '../../images/cummari.png'
import play from '../../images/playworking.png'
import coconat from '../../images/coconat.png'
import arctic from '../../images/arctic.png'
import neighborgoods from '../../images/neightbourgood.png'
import coliveme from '../../images/coliveme.png'
import haven from '../../images/haven.png'
import sundesk from '../../images/sundesk.png'
import kotornest from '../../images/kotor nest.png'
import portus from '../../images/portus.png'
import vine from '../../images/vine21.png'
import novovento from '../../images/novovento.png'
import palmera from '../../images/palmera.png'
import sonta from '../../images/sonta.png'
import cohaus from '../../images/cohaus.png'
import unity from '../../images/unity.png'
import collective from '../../images/thecollective.png'
import mutinerie from '../../images/mutinerie.png'
import hivearena from '../../images/hivearena.png'
import nestcopenhagen from '../../images/nestcopenhagen.png'
import unityhub from '../../images/unityhub.png'
import covive from '../../images/covive.png'
import livit from '../../images/livit coliving.png'
import puntadebarca from '../../images/puntadebarca.png'
import wildwifi from '../../images/wildwifi.png'
import kohub from '../../images/kohub.png'
import okacoliving from '../../images/okacoliving.png'
import kalart from '../../images/kalart.png'
import vecino from '../../images/vecinocoliving.png'
import workandbeach from '../../images/workandbeach.png'
import kasabola from '../../images/kasabbola.png'
import parkubud from '../../images/parkubud.jpg'
import hatcheryplace from '../../images/thehatcheryplace.jpg'
import greenroom from '../../images/greenroom.jpg'
import playhouse from '../../images/playhouse.jpg'
import sejaurbano from '../../images/sejaurbano.jpg'
import somewheredifferent from '../../images/somewheredifferent.jpg'
import conomad from '../../images/conomad.jpg'
import tertulia from '../../images/tertulia.png'
import surfscape from '../../images/surfescape.jpg'
import adventurekeys from '../../images/adventurekeys.jpg'
import amarilla from '../../images/amarillacoliving.jpg'
import colivingmadrid from '../../images/colivingmadrid.png'
import miluguesthouse from '../../images/miluguesthouse.jpeg'
import coimpact from '../../images/coimpact.jpg'
import colivingmarbella from '../../images/colivingmarbella.jpg'
import lakookaburra from '../../images/lakookaburra.jpg'
import openorbi from '../../images/openorbi.jpeg'
import nomadico from '../../images/nomadico.jpg'
import calcada from '../../images/calcada.jpg'
import localguesthouse from '../../images/localguesthouse.jpg'
import manascoliving from '../../images/manascoliving.jpg'
import alegoriacoliving from '../../images/alegoriacoliving.jpeg'
import feldbergcoliving from '../../images/feldbergcoliving.jpg'
import mediagocowocoli from '../../images/mediagocowocoli.jpg'
import homeofficemadeira from '../../images/homeofficemadeira.jpg'
import neptunocoliving from '../../images/neptunocoliving.jpg'
import movinncoliving from '../../images/movinncoliving.jpg'
import colive from '../../images/colive.jpg'
import somewheredifferentegypt from '../../images/somewheredifferentegypt.jpg'
import lilyfield from '../../images/lilyfield.jpg'
import cabin from '../../images/cabin.jpg'
import happypigeons from '../../images/happypigeonscoliving.jpg'
import palmacoliving from '../../images/palmacoliving.jpg'
import coliving1907 from '../../images/coliving1907.jpg'
import thebase from '../../images/thebase.jpg'
import frendzliving from '../../images/frendzliving.jpg'
import caasaliving from '../../images/caasaliving.jpg'
import easystreet from '../../images/easystreet.jpg'
import pohahouse from '../../images/pohahouse.jpg'

export const colivings = [
    {position: {lat: 40.354830, lng: -3.520030},adress:`C. Luis García Berlanga, 74, 28521 Rivas-Vaciamadrid, Madrid`, name:'Coliving Madrid', images:colivingmadrid, url:'/coliving-madrid', country:'Spain', ratingsAverage: 5, 
    reviews:[{
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    },
    {
        rating: 5,
        text: 'Great place to stay and meet new people!',
        user: {
        name: 'John Doe',
        image: 'https://example.com/john-doe.jpg'
        }
    }
    ],    
    },
    {position: {lat: 28.391100, lng: -16.524300},adress:`C. Tomás Zerolo, 18, 38300 La Orotava, Santa Cruz de Tenerife`, name:'Nine Coliving', images:nine, url:'https://www.ninecoliving.com', country:'Spain'},
    {position: {lat: 38.788670, lng: 0.161090},adress:`Carrer Príncep d'Astúries, 40, 03730 Xàbia, Alicante`, name:'Sun and Co.', images:sunandco, url:'https://sun-and-co.com', country:'Spain'},
    {position: {lat: 49.377340, lng: -1.403390},adress:`L'Isle Marie, 50360 imagesauville, Francia`, name:'Chateau Coliving', images:chateau, url:'https://chateaucoliving.com', country:'France'},
    {position: {lat: 44.898280, lng: 6.642680},adress:'3 Rue Louis Fauré, 05100 Briançon, Francia', name:'Cloud Citadel', images:cloudcitadel, url:'https://cloudcitadel.co/', country:'France'},
    {position: {lat: 42.004260, lng: -8.048120},adress:'Senderiz 33, 32850, Lobeira, Ourense', name:'Sende', images:sende, url:'https://www.sende.co/rural-coworking', country:'Spain'},
    {position: {lat: 42.355650, lng: -8.467430},adress:`Lugar Ramis 8, Anceu. Ponte Caldelas 36829, Pontevedra. Spain`, name:'Anceu Coliving', images:anceu, url:'https://anceu.com/', country:'Spain'},
    {position: {lat: 28.962690, lng: -13.546740},adress:'Calle Jacinto Borges Díaz 57, House 4. Arrecife, Lanzarote, Canary Island, Spain', name:'Pitaya Coliving', images:pitaya, url:'https://www.pitayacoliving.com/', country:'Spain'},
    {position: {lat: 28.090580, lng: -16.655240},adress:'C. Juana González González, 6, 38626 Arona, Santa Cruz de Tenerife', name:'Cactus Coliving', images:cactus, url:'https://cactuscoliving.com/', country:'Spain'},
    {position: {lat: 28.365740, lng: -16.381070},adress:'Camino de la Punta 29-31,38530 Candelaria, Tenerife Spain', name:'Maraya Coliving', images:maraya, url:'https://www.marayacoliving.com/', country:'Spain'},
    {position: {lat: 28.408850, lng: -16.505960},adress:'Calle Taoro 38, Santa UrsulaTenerife, Canary Islands', name:'Taoro coliving', images:taoro, url:'https://www.taorocoliving.com/', country:'Spain'},
    {position: {lat: 28.398470, lng: -16.539040},adress:'Calle Julio Romero de Torres 6 Puerto de la Cruz, SC de Tenerife', name:'Ikigai Coliving', images:ikigai, url:'https://www.ikigaicoliving.com/', country:'Spain'},
    {position: {lat: 47.350770, lng: 8.530510},adress:'TomoDomo GmbH Bellariastrasse 49 8038 Zürich', name:'TomoDomo', images:tomodomo, url:'https://en.tomodomo.ch/', country:'Switzerland'},
    {position: {lat: 42.495180, lng: 27.462280},adress:'ul. Aleksandar Stamboliiski 62 Burgas', name:'Burgas Coliving', images:burgas, url:'https://burgascoliving.com/', country:'Bulgary'},
    {position: {lat: 36.460461, lng: -5.723720},adress:'Alcalá de los Gazules, Cádiz', name:'Coliving Cadiz', images:colivingcadiz, url:'https://colivingcadiz.com/', country:'Spain'},
    {position: {lat: 35.912110, lng: 14.477070},adress:'Evolve Coliving, 30 dimora, Triq il Venew, San Ġwann SGN 1912', name:'Evolve Coliving', images:evolve, url:'https://evolvecoliving.io/', country:'Malta'},
    {position: {lat: 42.233430, lng: 2.285530},adress:'17860 Sant Joan de les Abadesses, Girona', name:'Fem coliving', images:fem, url:'https://www.femcoliving.com/', country:'Spain'},
    {position: {lat: 43.209120, lng: -9.002790},adress:'O Piñeiro, 10. 15118 - Laxe (A Coruña)', name:'I slow coliving', images:Islow, url:'https://islowcoliving.com/es/inicio/', country:'Spain'},
    {position: {lat: 34.757970, lng: 32.987830},adress:'To Hani, 1 Smyrnis Street , Paramytha, Cyprus, 4540', name:'to Hani Coliving', images:tohani, url:'https://es-la.facebook.com/tohanicoliving/', country:'Cyprus'},
    {position: {lat: 28.388150, lng: -16.523370},adress:'Calle León, 41 38300 La Orotava Santa Cruz de Tenerife Spain', name:'Gato Azul', images:gato, url:'https://gato-azul.com/' , country:'Spain'},
    {position: {lat: 38.553810, lng: -8.891600},adress:'Arrábida Natural Park', name:'Slow Cowork', images:slowcowork, url:'https://slowcowork.com/', country:'Portugal'},
    {position: {lat: 38.713210, lng: -9.136330},adress:'Rua da Madalena 214,1100-325 Lisboa', name:'Same Same', images:samesameliving, url:'https://www.samesameliving.com/', country:'Portugal'},
    {position: {lat: 38.742410, lng: -9.172540},adress:'Estrada de Benfica, 259, 1500-072 Lisboa', name:'Colisbon', images:colisbon, url:'https://colisbon.com/', country:'Portugal'},
    {position: {lat: 41.704090, lng: 44.783410},adress:'42 Vasil Petriashivili Tbilisi 0108', name:'LokalTsibilisi', images:lokal, url:'https://lokaltbilisi.com/', country:'Georgia'},
    {position: {lat: 42.815800, lng: 0.320440},adress:'Saint-Lary Soulan, Hautes Pyrénées', name:'Pyren Escape', images:imageUrl, url:'https://pyrenescape.com/', country:'France'},
    {position: {lat: 45.987390, lng: 7.183910},adress:'Rte de la Dranse 2 1945 Liddes', name:'Frilingue coliving', images:frilingue, url:'https://coliving.frilingue.ch/', country:'Switzerland'},
    {position: {lat: 17.054230, lng: -96.713226},adress:'Oaxaca', name:'Co404', images:co404, url:'https://co404.com/', country:'Mexico'},
    {position: {lat: 32.546570, lng: -85.676560},adress:'building 1, 1st dead end, 9th street, Khomuli village, Tskaltubo, 5400', name:'Coliving New Level', images:colivingnewlevel, url:'https://www.colivingnewlevel.su/home', country:'Georgia'},
    {position: {lat: 37.828300, lng: -25.640340},adress:'Rua De Baixo 35 Fenais Da Luz 9545-229', name:'Dwella Azores', images:dwellazores, url:'https://dwellazores.com/', country:'Portugal'},
    {position: {lat: 39.466520, lng: -0.367520},adress:'Joaquín Costa 10, Valencia, Spain 46005', name:'Vivarium Coliving', images:vivarium, url:'https://www.vivariumcoliving.com/', country:'Spain'},
    {position: {lat: 37.092540, lng: -8.682740},adress:'Rua da Torre pequena 18, Lagos', name:'Noma Village', images:noma, url:'https://www.nomavillage.com/', country:'Portugal'},
    {position: {lat: 11.268130, lng: -74.191210},adress:'Calle 12 # 1C – 50 Santa Marta Colombia', name:'Belafonte Coliving', images:belafonte, url:'https://belafonte.com.co/', country:'Colombia'},
    {position: {lat: 43.343120, lng: 13.697590},adress:'Contrada Foce Asola, 29, 62012, Civitanova Marche (MC)', name:'Navitas Coliving', images:navitas, url:'https://navitascoliving.com/en/', country:'Italy'},
    {position: {lat: 15.601800, lng: 73.783060},adress:'Assagao (Goa)', name:'NomadGao', images:nomadgao, url:'https://nomadgao.com/', country:'India'},
    {position: {lat: 46.178510, lng: 7.573910},adress:'Rue de la Batteuse 18, 3961 Grimentz', name:'Escape', images:ourescape, url:'https://www.ourescape.co/', country:'Switzerland'},
    {position: {lat: 45.523560, lng: -73.556410},adress:'1679 Rue de Champlain, Montreal, QC H2L 2S6', name:'Nomad Coliving', images:nomadcoliving, url:'https://www.nomadcoliving.com/', country:'Canada'},
    {position: {lat: 25.050577, lng: 55.201980},adress:'District 14, Jumeirah Village Circle - Dubai - Emiratos Árabes Unidos', name:'Hive coliv', images:hivecoliving, url:'https://hivecoliv.com/', country:'United Arab Emirates'},
    {position: {lat: 46.568817, lng: 11.167906},adress:'Haus Tribus, 116, 39010 Tisens, Autonome Provinz Bozen - Südtirol', name:'Franz & Mathilde', images:franzundmathilde, url:'https://franzundmathilde.com/', country:'Italy'},
    {position: {lat: 12.247610, lng: 102.530330},adress:'53 Chonpratan Road, Suthep, Mueang', name:'Hub 53', images:hub53, url:'https://www.hub53.com/', country:'Thailand'},
    {position: {lat: 39.854870, lng: 9.052050},adress:'Vicolo II° Garibaldi, 8, Laconi (OR)', name:'Treballu', images:treballu, url:'https://treballu.com/', country:'Italy'},
    {position: {lat: 40.173970, lng: 44.502750},adress:'Debet, Armenia', name:'Debed', images:debed, url:'https://debed.life/', country:'Armenia'},
    {position: {lat: 37.502480, lng: 15.087830},adress: `Catania - Sicily - Italy`, name:'Cummari', images:cummary, url:'https://www.cummari.com/', country:'Italy'},
    {position: {lat: 42.393600, lng: 18.689680},adress: `Đuraševići BB, Đuraševići 85323, Montenegro`, name:'Playworking', images:play, url:'https://www.playworking.me/', country:'Montenegro'},
    {position: {lat: 52.131840, lng: 12.517080},adress: `Klein Glien 25, 14806, Bad Belzig, Germany`, name:'Coconat', images:coconat, url:'https://coconat-space.com/', country:'Germany'},
    {position: {lat: 68.239807, lng: 13.631360},adress: `Tangstad 191, 8360 Bøstad, Noruega`, name:'Arctic Coworking', images:arctic, url:'https://www.arcticcoworking.com/', country:'Norway'},
    {position: {lat: -33.915600, lng: 18.417800},adress: `27 Somerset Rd, De Waterkant, Cape Town, 8001, South Africa`, name:'Neighbourgood', images:neighborgoods, url:'https://neighbourgood.co.za/', country:'South Africa'},
    {position: {lat: 6.081440, lng: 80.232190},adress: `Kanda, Ahangama, Galle, Western province, Sri Lanka`, name:'Colive Me', images:coliveme, url:'https://es.colive.me/', country:'Sri Lanka'},
    {position: {lat: 34.022390, lng: -118.479960},adress: `1641 16th St, Santa Monica, CA 90404, EEUU`, name:'Haven Coliving', images:haven, url:'https://havencoliving.com/', country:'EEUU'},
    {position: {lat: 34.404610, lng: -2.899540},adress: `Rue Tasga Oudrar 31, Taghazout 80022, Agadir, Morocco`, name:'Sun Desk', images:sundesk, url:'https://www.sun-desk.com/', country:'Morocco'},
    {position: {lat: 42.279016, lng: 18.837367},adress: `Stari Grad 444, Kotor, Montenegro`, name:'Kotor Nest', images:kotornest, url:'https://kotornest.me/', country:'Montenegro'},
    {position: {lat: 46.940050, lng: 17.757780},adress: `Vászoly, András u. 42, 8245 Hungría`, name:'Portus Home', images:portus, url:'https://portushome.co/', country:'Hungary'},
    {position: {lat: 44.420300, lng: 26.091160},adress: `Strada Constantin Bosianu 21 040505 / Bucuresti`, name:'Vine 21', images:vine, url:'https://www.vine21.ro/', country:'Bucharest'},
    {position: {lat: 37.836990, lng: -25.829630},adress: `Lomba de Cima 13, 9555-081 Ginetes, Portugal`, name:'Novovento', images:novovento, url:'https://www.novovento-coworkation.com/', country:'Portugal'},
    {position: {lat: 36.160880, lng: -5.815900},adress: `La  Zarzuela   Nº   38, 11393  Tarifa – Zahara de los atunes`, name:'Finca la Palmera', images:palmera, url:'https://finca-la-palmera.com/es/', country:'Spain'},
    {position: {lat: 45.601270, lng: 19.108130},adress: `Ive Andrića 5d, Sonta 25264, Serbia`, name:'Sonta Coliving', images:sonta, url:'https://sontacoliving.com/', country:'Serbia'},
    {position: {lat: 34.108300, lng: -118.193140},adress: `133 South Avenue 56, Los Angeles, CA 90042, USA`, name:'Cohaus', images:cohaus, url:'https://cohaus.com/', country:'EEUU'},
    {position: {lat: 37.012570, lng: 15.289420},adress: `Via Vasco de Gama, 2, 96100 Punta Miadressca SR, Italia`, name:'Kasabbola', images:kasabola, url:'https://www.kasabbola.it/', country:'Italy'},
    {position: {lat: 34.102550, lng: -118.323040},adress: `6118 Carlos ave. Los Angeles`, name:'Gosota', images:imageUrl, url:'https://gosota.com/', country:'EEUU'},
    {position: {lat: 9.930870, lng: -84.056570},adress: `Av. 10, La Granja, San José, San Pedro, Costa Rica`, name:'Unity Coliving', images:unity, url:'https://www.unitycoliving.com/', country:'Costa Rica'},
    {position: {lat: 51.497570, lng: -0.015390},adress: `20 Crossharbour Plaza London E14 9YF`, name:'The Collective', images:collective, url:'https://www.thecollective.com/', country:'United Kingdom'},
    {position: {lat: 48.408070, lng: 1.026330},adress: `28240 Saint-Victor-de-Buthon, Francia`, name:'Mutinerie Village', images:mutinerie, url:'https://sites.google.com/view/la-residence-verte/accueil', country:'France'},
    {position: {lat: 37.510690, lng: 126.923289},adress: `18, Yeouidaebang-ro 51-gil, Yeongdeungpo-gu, Seoul, Republic of Korea`, name:'Hive Arena', images:hivearena, url:'https://www.vine21.ro/', country:'Korea'},
    {position: {lat: 55.672920, lng: 12.562990},adress: `Reventlowsgade 10, 1651 København, Dinamarca`, name:'Nest Copenhagen', images:nestcopenhagen, url:'https://nestcopenhagen.dk/', country:'Denmark'},
    {position: {lat: 42.908800, lng: 23.798160},adress: `Зелин, Botevgrad, Komkata str. 2 ZeLLin House, Zelin, 2140 Botevgrad`, name:'Unity Hub', images:unityhub, url:'https://www.unityhub.co/', country:'Bulgary'},
    {position: {lat: 19.432608, lng: -99.133209},adress: `Mexico City`, name:'Covive', images:covive, url:'https://covive.mx/en/', country:'Mexico'},
    {position: {lat: 19.190700, lng: -99.465280},adress: `Av. Constituyentes 653, Piso 3, Col. 16 de Septiembre, Del Miguel Hidalgo`, name:'Livit', images:livit, url:'https://www.livit.com.mx/?lang=es', country:'Mexico'},
    {position: {lat: -1.397080, lng: -78.406330},adress: `Barrio Punta La Barca, parroquia Santa Marianita (Boca de, Pacoche, Manta 130852, Ecuador)`, name:'Punta la Barca', images:puntadebarca, url:'https://puntalabarca.com/', country:'Ecuador'},
    {position: {lat: -22.957640, lng: 18.490410},adress: `Namibia`, name:'Wild Wifi', images:wildwifi, url:'https://wildwifi.co/coliving/', country:'Namibia'},
    {position: {lat: 7.655380, lng: 99.041350},adress: `633, Krabi 81150, Tailandia`, name:'Kohub', images:kohub, url:'https://kohub.org/', country:'Thailand'},
    {position: {lat: -29.996441, lng: -51.200920},adress: `Tv. São José, 455 - Navegantes, Porto Alegre - RS, 90240-200, Brasil`, name:'Oka coliving', images:okacoliving, url:'https://www.okacoliving.com.br/en', country:'Brasil'},
    {position: {lat: 41.714150, lng: 2.427530},adress: `Can Garballer Nou - Diseminado Afores, 102 Carretera, BV-5301, km 6.5, 08461, Barcelona`, name:'Kalart', images:kalart, url:'https://kalart.org/', country:'Spain'},
    {position: {lat: 6.213260, lng: -75.572380},adress: `Calle 11a #43D 64 Barrio Manila, El Poblado, Antioquia Medellin`, name:'Vecino Coliving', images:vecino, url:'https://www.vecinocoliving.com/', country:'Colombia'},
    {position: {lat: 31.499680, lng: -9.761670},adress: `90 quartier des dunes Rue Amira lalla Meryem 44000 Essaouira Morocco`, name:'Work and Beach', images:workandbeach, url:'https://www.workandbeach.com/', country:'Morocco'},
    {position: {lat: -8.505200, lng: 115.265610},adress: `Parq Ubud, Jl. Sri Wedari No.24, Tegallalang, Ubud, Gianyar Regency, Bali 80571, Indonesia`, name:'Park Ubud', images:parkubud, url:'https://parqubud.com/', country:'Indonesia'},
    {position: {lat: 3.043510, lng: 101.577420},adress: `49, Jalan USJ 11/3d, Usj 11, 47620 Subang Jaya, Selangor, Malasia`, name:'The Hatchery place', images:hatcheryplace, url:'https://www.thehatcheryplace.com/', country:'Malaysia'},
    {position: {lat: -34.056380, lng: 24.922340},adress: `2A Da Gama Road, Jeffreys Bay, South Africa`, name:'The Green Room', images:greenroom, url:'https://greenroomjbay.com/coliving', country:'South Africa'},
    {position: {lat: -0.204300, lng: -78.481170},adress: `Julio Zaldumbide N24741, Quito 170109, Ecuador`, name:'Play House', images:playhouse, url:'https://playhousequito.negocio.site/', country:'Ecuador'},
    {position: {lat: -12.141100, lng: -38.422320},adress: `Av. Juracy Magalhães, 46 - Centro, Alagoinhas - BA, 48005-440, Brasil`, name:'Seja Urbano', images:sejaurbano, url:'https://en.sejaurbano.com.br/', country:'Brasil'},
    {position: {lat: 53.866050, lng: -1.691410},adress: `22 Kirk Ln, Yeadon, Leeds LS19 7ET, Reino Unido`, name:'Somewhere Different', images:somewheredifferent, url:'https://www.somewheredifferent.com/uk/', country:'United Kingdom'},
    {position: {lat: 28.726730, lng: -13.875850},adress: `Av. Juan Carlos I, 3, 35660 Corralejo, Las Palmas`, name:'Conomad', images:conomad, url:'http://conomad.es/', country:'Spain'},
    {position: {lat: 43.938430, lng: 11.469710},adress: `Società Agricola Il Monte s.s. adressalità Rossoio 45 50039, Vicchio Firenze, Italia`, name:'Tertulia', images:tertulia, url:'https://tertulia.farm/en/', country:'Italy'},
    {position: {lat: 28.683590, lng: -13.945390},adress: `C. Las Cercas, 9, 35650 Lajares, Las Palmas`, name:'Surf Escape', images:surfscape, url:'https://www.surfescape.com/', country:'Spain'},
    {position: {lat: 30.442784, lng: -9.594878},adress: `27 Rue Tasga Oudrar, Taghazout 80022, Marruecos`, name:'Adventure Keys', images:adventurekeys, url:'https://www.adventurekeys.com/', country:'Morocco'},
    {position: {lat: 46.190570, lng: 6.701240},adress: `458 Route de L'Élé, Montriond, Haute-Savoie, France, 74110`, name:'Heyday Chalet', images:heyday, url:'https://www.heydaychalet.com/', country:'France'},
    {position: {lat: 28.378460, lng: -16.585270},adress: `C. el Medio Arriba, 40, 38416 Los Realejos, Santa Cruz de Tenerife`, name:'Amarilla Coliving', images:amarilla, url:'https://amarilla-co.com/coliving/', country:'Spain'},
    {position: {lat: 39.360680, lng: -9.308120},adress: `Estrada dos Casais 68, 2520-103 Ferrel, Portugal`, name:'Milu guesthouse', images:miluguesthouse, url:'https://www.miluguesthouse.com/', country:'Portugal'},
    {position: {lat: 41.396850, lng: 2.165990},adress: `Carrer del Bruc, 123, 08037 Barcelona`, name:'Coimpact coliving', images:coimpact, url:'https://coimpactbcn.com/', country:'Spain'},
    {position: {lat: 36.509941, lng: -4.886350},adress: `Marbella`, name:'Coliving Marbella', images:colivingmarbella, url:'https://www.colivingmarbella.com/', country:'Spain'},
    {position: {lat: 41.547840, lng: -3.072510},adress: `Camino Lodares-Valdenarros Km. 1,4 Lodares de Osma (Soria)`, name:'Open orbi', images:openorbi, url:'https://openorbi.com/', country:'Spain'},
    {position: {lat: 32.735530, lng: -17.207250},adress: `9370-423 Jardim do Mar, Portugal`, name:'Nomadico', images:nomadico, url:'https://www.nomadico.io/', country:'Portugal'},
    {position: {lat: 37.126700, lng: -7.651480},adress: `Calçada Dona Ana 12, Tavira 8800-308`, name:'Calçada guesthouse', images:calcada, url:'https://calcadaguesthouse.com/index.html', country:'Portugal'},
    {position: {lat: 37.014230, lng: -8.938420},adress: `Rua Roça do Veiga 8650-387 Sagres`, name:'Local guesthouse', images:localguesthouse, url:'https://localguesthouse.pt/', country:'Portugal'},
    {position: {lat: 37.954840, lng: -8.870840},adress: `Rua Vasco da Gama, 46, Sines, Portugal`, name:'Manas Coliving', images:manascoliving, url:'https://manascoliving.pt/', country:'Portugal'},
    {position: {lat: 37.391070, lng: -5.994050},adress: `Calle Cerrajería 23, Sevilla`, name:'Alegoria experience', images:alegoriacoliving, url:'https://alegoriaexperience.com/', country:'Spain'},
    {position: {lat: 47.565960, lng: 7.590260},adress: `Feldbergstrasse 42 CH - 4057 Basel`, name:'Feldberg Coliving', images:feldbergcoliving, url:'https://coliving.feldberg42.ch/', country:'Switzerland'},
    {position: {lat: 48.740630, lng: 9.299380},adress: `Dick Areal, Kollwitzstr.1, 73728 Esslingen`, name:'Mediago Cowocoli', images:mediagocowocoli, url:'http://eng.cowocoli.de/', country:'Germany'},
    {position: {lat: 36.669170, lng: -4.744710},adress: `Partido Alaminos 386 Poligono 17, 29100 Coin, Malaga`, name:'La Kookaburra', images:lakookaburra, url:'https://www.lakookaburra.com/', country:'Spain'},
    {position: {lat: 32.711090, lng: -16.825810},adress: `Caminho do Cabeço Alto, Santo Antonio da Serra, 9100-249, Madeira Island`, name:'Home Office Madeira', images:homeofficemadeira, url:'https://en.homeoffice-madeira.com/', country:'Portugal'},
    {position: {lat: 52.235320, lng: 6.851280},adress: `Neptunusstraat 22 7521 WD Enschede Nederland`, name:'Neptuno Coliving', images:neptunocoliving, url:'https://neptuno-coliving-space.business.site/', country:'Netherlands'},
    {position: {lat: 55.683050, lng: 12.588790},adress: `Dronningens Tværgade 9B, 1. DK-1302 Copenhagen K`, name:'Movinn Coliving', images:movinncoliving, url:'https://coliving.movinn.com/', country:'Denmark'},
    {position: {lat: 59.333771, lng: 18.044270},adress: `Fleminggatan 18, 112 26 Stockholm`, name:'Colive', images:colive, url:'https://colive.se/', country:'Sweden'},
    {position: {lat: 31.204080, lng: 27.500530},adress: `قصر شالي, Matrouh Governorate, Egipt`, name:'Somewhere different egypt', images:somewheredifferentegypt, url:'https://www.somewheredifferent.com/egypt/', country:'Egypt'},
    {position: {lat: 40.179050, lng: -75.378100},adress: `1310 Hollow Rd, Collegeville, PA 19426, United States`, name:'Lilyfield', images:lilyfield, url:'https://lilyfieldfarmstay.business.site/', country:'EEUU'},
    {position: {lat: 30.332170, lng: -98.385280},adress: `Texas Hill City, United States`, name:'Cabin', images:cabin, url:'https://www.cabin.city/', country:'EEUU'},
    {position: {lat: 52.548340, lng: 13.426620},adress: `Erich-Weinert-Straße 78, 10439 Berlin, Alemania`, name:'Happy Pigeons', images:happypigeons, url:'https://www.happypigeons.com/', country:'Germany'},
    {position: {lat: 39.568880, lng: 2.625340},adress: `Carrer de Son Armadans, 15, 07014 Palma, Illes Balears`, name:'Palma Coliving', images:palmacoliving, url:'https://palmacoliving.co', country:'Spain'},
    {position: {lat: 52.571850, lng: 13.403280},adress: `Pestalozzistraße 5-8, 13187 Berlin, Germany`, name:'TheBase', images:thebase, url:'https://www.jointhebase.co/', country:'Germany'},
    {position: {lat: 52.523430, lng: 13.411440},adress: `Einstelpark, Berlin, Germany`, name:'Frendz', images:frendzliving, url:'https://www.frendzliving.com/', country:'Germany'},
    {position: {lat: 52.513880, lng: 13.396870},adress: 'Oberwallstraße 6 10117 Berlin Germany', name:'Caasa living', images:caasaliving, url:'https://www.caasaliving.com/', country:'Germany'},
    {position: {lat: 48.135520, lng: 11.572100},adress: `Oberanger 6 80331 München`, name:'Easy street', images:easystreet, url:'https://www.easy-street.de/en/', country:'Germany'},
    {position: {lat: 51.957360, lng: 7.637870},adress: `Bremer Platz 50-52 48155 Münster`, name:'Poha House', images:pohahouse, url:'https://pohahouse.com/', country:'Germany'},
    {position: {lat: 37.973110, lng: -1.215070},adress: `C. Escritor Benito Pérez Galdós, 49, 35002`, name:'Coliving 1907', images:coliving1907, url:'https://coliving1907.com/', country:'Spain'},
]

