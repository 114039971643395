import React from "react";
import { Container, ReviewWrapper } from "./wrappers.tsx";

// Helper component to render rating as stars
const RatingStars = ({ rating }) => {
  return (
    <div style={{ display: 'flex' }}>
      {[...Array(5)].map((_, index) => (
        <span key={index} style={{ color: index < rating ? '#FFD700' : '#e4e5e9' }}>
          ★
        </span>
      ))}
    </div>
  );
};

export const Reviews = ({ reviews }) => {
  if (reviews === undefined) {
    return (
      <Container>
        <p>Loading reviews...</p>
      </Container>
    );
  }

  return (
    <Container>
      <h2 style={{ margin: '1em 0' }}>Reviews ({reviews.length})</h2>
      {reviews.length === 0 ? (
        <p>No reviews available.</p>
      ) : (
        reviews.map((review) => (
          <ReviewWrapper key={review._id}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <img
                src={review.user.photo}
                alt={`${review.user.name}'s profile`}
                style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}
              />
              <div>
                <p style={{ margin: 0, fontWeight: 'bold' }}>{review.user.name}</p>
                <RatingStars rating={review.rating} />
              </div>
            </div>
            <p>{review.review}</p>
          </ReviewWrapper>
        ))
      )}
    </Container>
  );
};
