import React from "react";
import { RoomContainer, ImageContainer } from "./wrappers.tsx";

export const Room = ({name, description, image, alt, roomId}) => {
  return (
    <RoomContainer id={roomId}>
      <h3>{name}</h3>
      <p>{description}</p>
      <ImageContainer>
      {image.map((imageUrl, index) => (
        <img key={index} src={imageUrl} alt={alt} />
      ))}        
      </ImageContainer>
    </RoomContainer>
  );
}